import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import imgLogo from '../src/img/CrearGrupo/logo.svg';
import imgBack from '../src/img/CrearGrupo/back_home.svg';
import imgbullet from '../src/img/CrearGrupo/bullet.svg';
import imgCiclistas from '../src/img/CrearGrupo/grupo-ciclistas.png';
import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import {FaCheckCircle, FaTrash,BiFilterAlt,FaEyeSlash,FaEye } from 'react-icons/fa'; // npm i react-icons

function CrearGrupo()
{
    const [show, setShow] = useState(false);
    const [bEyes, setEyes] = useState(false);
    const [correo, setCorreo] = useState('');
    const [contraseña, setContraseña] = useState('');
    const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
    


    const navigate = useNavigate();

    const handleClickEyes = () => {
  
      if(bEyes == true)
      {
        setEyes(false);
      }
      else
      {
        setEyes(true);
      }
    }
  
    const ValidaAcceso = async (correo, contraseña) => {
  
      if(correo == '' && contraseña == '')
      {
          return;
      }
  
      await fetch('https://conectate-peru.com/api/ApiValidaAcceso.php', {
         method: 'POST',
         body: JSON.stringify({
            correo: correo,
            clave: contraseña,
         }),
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
         },
      })
         .then((response) => response.json())
         .then((data) => {
            //setPosts((posts) => [data, ...posts]);
  
            const respuesta = JSON.parse(data);
            //console.log(respuesta);
  
            if(respuesta == 0 || correo == '' || contraseña == '')
            {
               alert("Usuario y/o contraseña incorrecta, Verifique sus datos.");   
            }else if (respuesta != 0){
  
              sessionStorage.setItem("UsuarioActivo",JSON.stringify(correo));
              setIdusuario(respuesta);
  
              setCorreo('');
              setContraseña('');
              navigate('/HomeUser');
              //window.location.reload(true);
            }
  
       
         })
         .catch((err) => {
            console.log(err.message);
         });
   };
  
   const handleSubmit2 = (e) => {
  
      e.preventDefault();
      ValidaAcceso(correo, contraseña);
  
   }; 

   const handleRedirectHome = () =>{

    navigate(-1);

  }

  const handleRedirectCrearGrupo = () =>{

    navigate("/CreandoGrupo2");

  }


return(
    <div className="split-container">
      <div className="split-left">
        <Container>
            <Row>
                <Col sm={2}>
                    <img src={imgBack} style={{cursor:'pointer'}} onClick={handleRedirectHome}></img>
                </Col>
                <Col sm={8}>
                   
                </Col>
            </Row>
        </Container>
        <Container style={{padding: '0 0 0 0'}}>
  <Row>
    <Col sm={12}>
      <Container style={{paddingTop: 'calc(2em + 2vw)'}}>
        <Row>
          <Col sm={12}>
            <center>
              <img src={imgLogo} alt="Logo"></img>
            </center>
          </Col>
        </Row>
        <Row style={{paddingTop: '40px'}}>
          <Col sm={12}>
            <Container>
              <Row>
                <Col sm={2}></Col>
                <Col sm={8}>
                  <center>
                    <p style={{fontSize: '16pt'}}>
                      <b>¿No encuentra la actividad o evento que deseas participar?</b>
                    </p>
                  </center>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row style={{paddingTop: '0'}}>
          <Col sm={12}>
            <center>
              <p style={{fontSize: '20pt'}}>
                <b style={{color: '#FF2D52 '}}>Únete a nuestra plataforma</b>
              </p>
            </center>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Container style={{paddingTop: '20px', paddingBottom: '20px'}}>
              <Row>
                <Col sm={2}></Col>
                <Col sm={8} className='ContainerPreCrearGrupo'>
                  <Container>
                    <Row>
                      <Col sm={1} className="d-none d-sm-block">
                        <img src={imgbullet} alt="Bullet" style={{float: 'right'}}></img>
                      </Col>
                      <Col sm={11}>
                        <p>Aquí tendrás la oportunidad de crear tu propio grupo y disfrutar de la libertad de organizar tus eventos.</p>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </Container>
          </Col>
          <Col sm={12}>
            <Container style={{paddingBottom: '20px'}}>
              <Row>
                <Col sm={2}></Col>
                <Col sm={8} className='ContainerPreCrearGrupo'>
                  <Container>
                    <Row>
                      <Col sm={1} className="d-none d-sm-block">
                        <img src={imgbullet} alt="Bullet" style={{paddingTop: '10px', float: 'right'}}></img>
                      </Col>
                      <Col sm={11}>
                        <p>Podrás decidir quien forma parte de tu grupo centro de la comunidad.</p>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </Container>
          </Col>
          <Col sm={12}>
            <Container style={{paddingBottom: '20px'}}>
              <Row>
                <Col sm={2}></Col>
                <Col sm={8} className='ContainerPreCrearGrupo'>
                  <Container>
                    <Row>
                      <Col sm={1} className="d-none d-sm-block">
                        <img src={imgbullet} alt="Bullet" style={{paddingTop: '10px', float: 'right'}}></img>
                      </Col>
                      <Col sm={11}>
                        <p>Además podrás generar ingresos económicos si así lo deseas.</p>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </Container>
          </Col>
          <Col sm={12}>
            <Container style={{paddingBottom: '20px'}}>
              <Row>
                <Col sm={2}></Col>
                <Col sm={8} className='ContainerPreCrearGrupo'>
                  <Container>
                    <Row>
                      <Col sm={1} className="d-none d-sm-block">
                        <img src={imgbullet} alt="Bullet" style={{paddingTop: '10px', float: 'right'}}></img>
                      </Col>
                      <Col sm={11}>
                        <p>Tu grupo será promocionado continuamente y tendrás apoyo y asistencia para ayudar a crecer tu comunidad.</p>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </Container>
          </Col>
          <Col sm={12}>
            <Container>
              <Row>
                <Col sm={4}></Col>
                <Col sm={4}>
                  <center>
                    <Button variant="primary3" type='button' onClick={handleRedirectCrearGrupo}>
                      <b style={{fontSize: '12pt'}}>Crear mi grupo</b>
                    </Button>
                  </center>
                </Col>
                <Col sm={4}></Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Col>
  </Row>
</Container>;
</div>
      <div className="split-right d-none d-sm-block">
        <img src={imgCiclistas} style={{width:'100%',height:'100%',objectFit:'cover',alignItems:'center',justifyContent:'center'}}></img>
      </div>
    </div>

);

}

export default CrearGrupo;