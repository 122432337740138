import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';
import imgVerTodos from '../src/img/Home/ver_todos.svg';
import Calendar from './Components/Calendar';

import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "../src/Components/LoadingSpinner";
import EventosBusqueda from './Components/EventosBusqueda';
import TopGrupos from './Components/TopGrupos';
import { useLocalStorage } from './useLocalStorage';


function HomeUser(){

const navigate = useNavigate();

let itop = 0;

const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [nombreUsuario, setnombreUsuario] = useLocalStorage('nombreUsuario','')
const [apellidoUsuario, setapellidoUsuario] = useLocalStorage('apellidoUsuario','')

const [usuarioActivo,setusuarioActivo] = useState(() => {

    const UsuActivo = sessionStorage.getItem("UsuarioActivo");
    
    if(UsuActivo == null)
    {
        return;
    }
    
    const UsuActivoS  = JSON.parse(UsuActivo);
  
    return UsuActivoS || "";
    });

async function fetchApi(top){

    /*if(usuarioActivo == null || usuarioActivo == '')
    {
        navigate("/")
        return;
    }*/

    setIsLoading(true);
    let fd = new FormData() 
    fd.append("top",top)

    const res = await axios.post('https://conectate-peru.com/api/ApiListarEventos.php',fd);
    setItems2(res.data.items);
    //setIsVisble(res.data.items[0].visible);
    setIsLoading(false);

    }

useEffect(() => {
    
        fetchApi(itop);
    
    },[])

    const handleClick1  = (e) => 
    {

        if(counter >= 0)
        {
        setCounter(counter + 4);

        fetchApi(counter + 4);
        }
        
        //console.log(counter);
    
    }

    const VerTodos  = (e) => 
    {

        navigate('/ListaGrupos');
        
    }

    function BuscarContenido()
    {
    const formulario = document.querySelector('#txtsearch');

    findString(formulario.value.toUpperCase());
    }


    function findString (str) 
    {
        if (parseInt(navigator.appVersion) < 4) return;
  let strFound = false;

  if (window.find) {
    // Código para navegadores que admiten window.find
    strFound = window.find(str);
    if (!strFound) {
      strFound = window.find(str, 0, 1);
      while (window.find(str, 0, 1)) continue;
    }
  } else if (navigator.appName.indexOf("Microsoft") !== -1) {
    // Código específico para Internet Explorer
    let TRange = window.document.body.createTextRange();
    TRange.collapse(false);
    strFound = TRange.findText(str);
    if (strFound) TRange.select();
    if (!TRange || strFound === 0) {
      TRange = window.document.body.createTextRange();
      strFound = TRange.findText(str);
      if (strFound) TRange.select();
    }
  } else if (navigator.appName === "Opera") {
    // Opera no soportado
    alert("Los navegadores Opera no son soportados. Disculpe las molestias.");
    return;
  }

    if (!strFound) alert("¡Cadena '" + str + "' no encontrada!");
    return strFound;
    }


return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)',paddingLeft:'15px'}}>
            <Row style={{paddingBottom:'calc(2em + 2vw)'}}>
                <Col sm={1}>
                </Col>
                <Col sm={10}>

                    <Container style={{padding:'0 0 0 0'}}>
                            <Row>
                                <Col sm={12}>
                                    <p style={{fontSize:'calc(1.5em + 1.5vw)'}}>Bienvenido <b style={{color:'#FF2D52 '}}> 
                                    {(usuarioActivo == null || usuarioActivo == '' || usuarioActivo == 'undefined') ? <></> : <>{nombreUsuario} {apellidoUsuario} </>}
                                    </b></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <p><b>Empecemos a conectar</b></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>

                                <div className='text-white'>
                                    <Container >
                                        <Row>
                                            <Col xs={1}>
                                                <img src={imgLupa} className='imglupa2'></img>
                                            </Col>
                                            <Col xs={9}>
                                            <input id='txtsearch' style={{fontSize:'10pt'}} placeholder='Palabra clave del evento'></input>
                                            </Col>
                                            <Col xs={2}>
                                                <button className='btn-primarybuscar' onClick={BuscarContenido}><img src={imgSend} style={{height:'35px'}}></img></button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                </Col>
                                <Col sm={2} className="d-none d-sm-block">

                                <div className='text-white'>
                                    <Container style={{padding:'0 0 0 0'}} >
                                        <Row>
                                            <Col xs={12}>
                                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
                                            <Form.Select 
                                            //value={tipodocumento}
                                            //onChange={(e) => settipodocumento(e.target.value)}
                                            className='imgCbo' >
                                            <option Select value={1}>Presencial</option>
                                            <option value={2}>Virtual</option>
                                                
                                                </Form.Select>
                                            </Form.Group>
                                            </Col>
                                            
                                        </Row>
                                    </Container>
                                </div>
                                </Col>
                                <Col sm={2} className="d-none d-sm-block">

                                <div className='text-white'>
                                    <Container style={{padding:'0 0 0 0'}} >
                                        <Row>
                                            <Col xs={12}>
                                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
                                            <Form.Select 
                                            //value={tipodocumento}
                                            //onChange={(e) => settipodocumento(e.target.value)}
                                            className='imgCbo' >
                                            <option Select value={1}>Lima</option>
                                            <option value={2}>Arequipa</option>
                                                
                                                </Form.Select>
                                            </Form.Group>
                                            </Col>
                                            
                                        </Row>
                                    </Container>
                                </div>
                                </Col>
                                <Col sm={2} className="d-none d-sm-block">

                                <div className='text-white'>
                                    <Container style={{padding:'0 0 0 0'}} >
                                        <Row>
                                            <Col xs={12}>
                                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
                                            <Form.Select 
                                            //value={tipodocumento}
                                            //onChange={(e) => settipodocumento(e.target.value)}
                                            className='imgCbo' >
                                            <option Select value={1}>Miraflores</option>
                                            <option value={2}>Surco</option>
                                                
                                                </Form.Select>
                                            </Form.Group>
                                            </Col>
                                            
                                        </Row>
                                    </Container>
                                </div>
                                </Col>
                            </Row>
                       
                    </Container>

                </Col>
                <Col sm={1}>
                </Col>
            </Row>
            <Row>
                <Col sm={1}>
                </Col>
                <Col sm={10}>
                <p><b style={{fontStyle:'italic',color:'#484848',fontSize:'18pt'}}>Eventos del día</b></p>
                </Col>
                <Col sm={1}>
                </Col>
            </Row>
            <Row>
            <Col sm={1}>
                </Col>
                <Col sm={6}>
                    <div style={{minHeight:'600px',overflowX:'auto',padding:'0 0 0 0'}}>
                    {isLoading ? <LoadingSpinner/> :
                    <>
                    <ul id='ulBusqueda'>
                    { !items2 ? 'Cargando Top Conectate....' : items2.map( (item,index) => {
                        
                    
                            /*onClick={(e) => handleRedirect(item.idAnuncio,item.Latitud, item.Longitud)}*/
                            return(
                                <li   key={index} className='ulbuquedacss'>
                                <EventosBusqueda ImagenPortada = {item.ImagenPortada} FechaInicio = {item.FechaInicio} FechaFin = {1} HoraInicio = {item.HoraInicio} HoraFin = {item.HoraFin} Titulo = {item.Titulo} Direccion = {item.Direccion} Participantes = {item.Participantes} IdEvento = {item.Id}></EventosBusqueda>
                                </li>
                            
                            )
                      
                    })}
            
            </ul>
            </>
            }
                    </div>
                </Col>
                <Col sm={4} className="d-none d-sm-block">
                    <Container>
                        <Row>
                            <Col sm={12} style={{paddingTop:'20px'}}>
                                <Calendar></Calendar>
                            </Col>

                            {(usuarioActivo == null || usuarioActivo == '' || usuarioActivo == 'undefined') ? <></> 
                            : 
                            <>
                            <Col sm={12}>
                                <Container style={{padding:'0 0 0 0'}}>
                                    <Row>
                                        <Col sm={2}>
                                        </Col>
                                        <Col sm={4}>
                                        <p><b style={{fontSize:'14pt'}}>Mis Grupos</b></p>
                                        </Col>
                                        <Col sm={4}>
                                        <img src={imgVerTodos} style={{cursor:'pointer'}} onClick={VerTodos}></img>
                                        </Col>
                                        <Col sm={2}>
                                        
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col sm={12}>
                                <TopGrupos></TopGrupos>
                            </Col>
                            </>}
                        </Row>
                    </Container>
                </Col>
                <Col sm={1}>
                </Col>
            </Row>


        </Container>
    </Layout>



</>);


}

export default HomeUser;