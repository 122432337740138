import React,{useState,useEffect} from "react";
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import { Link,useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Carousel from "react-bootstrap/Carousel";
import LoadingSpinner from "./LoadingSpinner";
import imgTagVarios from "../img/Home/tag_varios.svg";

const zoomOutProperties = {
    duration: 5000,
    transitionDuration: false,
    infinite: false,
    indicators: false,
    scale: 0.4,
    arrows: true
    };

function EventosBusqueda({ImagenPortada,FechaInicio,FechaFin,HoraInicio,HoraFin,Titulo,Direccion,Participantes,IdEvento})
{

const [items, setItems] = useState();
const [index, setIndex] = useState(0);
const [letrasAleatorias, setLetrasAleatorias] = useState([]);
const [fecha, setfecha] = useState(new Date())
const [fechaFormat, setFechaformateada] = useState('')
const navigate = useNavigate();

const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleRedirect = () => {
         
    
    sessionStorage.setItem("EventoActual",JSON.stringify(IdEvento));
    navigate('/EventoDetalle');
  
  }


async function fetchApi()
{
  
   // const jsonNew = JSON.parse(ListaImagenes);
   // setItems(jsonNew.items);
   const options = { year: 'numeric', month: 'long', day: 'numeric' };

    setfecha(FechaInicio)

    const fechaFormateada = fecha.toLocaleDateString('es-ES', options);

    setFechaformateada(fechaFormateada);

    generarLetrasAleatorias();

}

const generarLetrasAleatorias = () => {
    const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const letrasArray = letras.split('');
    const letrasAleatoriasArray = [];

    for (let i = 0; i < 3; i++) {
      const indiceAleatorio = Math.floor(Math.random() * letrasArray.length);
      letrasAleatoriasArray.push(letrasArray[indiceAleatorio]);
    }

    setLetrasAleatorias(letrasAleatoriasArray);
  };

useEffect(() => {
    fetchApi();
    },[])

    return (
        <>
         <Container style={{ padding: '30px 0 0 0', overflow: 'hidden' }}>
  <Row className="justify-content-center align-items-center">
    <Col xs={12} sm={6} className="text-center">
      <LazyLoadImage
        effect="blur"
        src={ImagenPortada}
        style={{
          width: "100%",
          borderTopRightRadius: '10px',
          borderTopLeftRadius: "10px",
          height: "calc(170px + 2vmin)",
          objectFit: "cover"
        }}
        onClick={(e) => handleRedirect()}
      />
    </Col>
    <Col xs={12} sm={6}>
      <Container style={{ padding: '0' }}>
        <Row>
          <Col xs={12}>
            <p className="TextBusqueda"><b>{fechaFormat} | {HoraInicio} - {HoraFin}</b></p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="TextBusqueda2"><b>{Titulo}</b></p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="TextBusqueda">{Direccion}</p>
          </Col>
        </Row>
        <Row>
        <Col xs={12} xm={4} className="LetrasEventos">
          {letrasAleatorias.map((letra, index) => (
            (index === 0) ? <div className="circle red" key={index}><center><p style={{ padding: '5px 0 0 0' }}>{letra}</p></center></div> :
              (index === 1) ? <div className="circle green" key={index}><center><p style={{ padding: '5px 0 0 0' }}>{letra}</p></center></div> :
                <div className="circle blue" key={index}><center><p style={{ padding: '5px 0 0 0' }}>{letra}</p></center></div>
          ))}
        </Col>
        <Col xs={12} xm={8}>
          {FechaFin == 1 ? <></> : <><p className="TextAsistentes">{Participantes} asistentes</p></>}
          
        </Col>
      </Row>

      </Container>
    </Col>
  </Row>
</Container>


        </>
    );


}




export default EventosBusqueda;