import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';
import imgVerTodos from '../src/img/Home/ver_todos.svg';
import Calendar from './Components/Calendar';

import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "./Components/LoadingSpinner";
import EventosBusqueda from './Components/EventosBusqueda';
import TopGrupos from './Components/TopGrupos';
import { useLocalStorage } from './useLocalStorage';
import BusquedaGrupos from './Components/BusquedaGrupos';


function ListaGrupos(){


let itop = 0;

const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [cantidadGrupos, setCantidadGrupos] = useState(0)
const [usuarioActivo,setusuarioActivo] = useState(() => {

    const UsuActivo = sessionStorage.getItem("UsuarioActivo");
    
    if(UsuActivo == null)
    {
        return;
    }
    
    const UsuActivoS  = JSON.parse(UsuActivo);
  
    return UsuActivoS || "";
    });

async function fetchApi(top){

    

    setIsLoading(true);
    let fd = new FormData() 
    fd.append("top",top)

    const res = await axios.post('https://conectate-peru.com/api/ApiListarGruposAll.php',fd);
    setItems2(res.data.items);
    //setIsVisble(res.data.items[0].visible);
    setIsLoading(false);

    }

useEffect(() => {
    fetchApi(itop);
    
    },[])

    const handleClick1  = (e) => 
    {

        if(counter >= 0)
        {
        setCounter(counter + 4);

        fetchApi(counter + 4);
        }
        
        //console.log(counter);
    
    }

    const handleClick2  = (e) => 
    {

        if(counter > 0)
        {
        setCounter(counter - 4);
        
        fetchApi(counter - 4);
        }
        //console.log(counter);
        
    }


return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)',paddingLeft:'0'}}>
            <Row style={{paddingBottom:'calc(2em + 2vw)'}}>
                <Col sm={1}>
                </Col>
                <Col sm={10}>

                    <Container style={{padding:'0 0 0 0'}}>
                            <Row>
                                <Col sm={6}>

                                <div className='text-white'>
                                    <Container >
                                        <Row>
                                            <Col xs={1}>
                                                <img src={imgLupa} className='imglupa2'></img>
                                            </Col>
                                            <Col xs={9}>
                                            <input style={{fontSize:'10pt'}} placeholder='Palabra clave del evento'></input>
                                            </Col>
                                            <Col xs={2}>
                                                <button className='btn-primarybuscar'><img src={imgSend} style={{height:'35px'}}></img></button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                </Col>
                                <Col sm={2} className="d-none d-sm-block">

                                <div>
                                    <Container style={{padding:'0 0 0 0'}} >
                                        <Row>
                                            <Col sm={12}>
                                           
                                            </Col>
                                            
                                        </Row>
                                    </Container>
                                </div>
                                </Col>
                                <Col sm={2} className="d-none d-sm-block">

                                <div>
                                    <Container style={{padding:'0 0 0 0'}} >
                                        <Row>
                                            <Col sm={12}>
                                            
                                            </Col>
                                            
                                        </Row>
                                    </Container>
                                </div>
                                </Col>
                                <Col sm={2} className="d-none d-sm-block">

                                <div>
                                    <Container style={{padding:'0 0 0 0'}} >
                                        <Row>
                                            <Col sm={12}>
                                           
                                            </Col>
                                            
                                        </Row>
                                    </Container>
                                </div>
                                </Col>
                            </Row>
                       
                    </Container>

                </Col>
                <Col sm={1}>
                </Col>
            </Row>
            <Row>
                <Col sm={1}>
                </Col>
                <Col sm={10}>
                <p><b style={{fontStyle:'italic',color:'#484848',fontSize:'16pt'}}>Conecta con Grupos</b></p>
                </Col>
                <Col sm={1}>
                </Col>
            </Row>
            <Row >
                <Col sm={1}>
                </Col>
                <Col sm={10}>
                <p style={{color:'#484848',fontSize:'10pt'}}>{cantidadGrupos} grupos</p>
                </Col>
                <Col sm={1}>
                </Col>
            </Row>
            <Row>
            <Col sm={1}>
                </Col>
                <Col sm={6}>
                    <div style={{minHeight:'600px',overflowX:'auto',padding:'0 0 0 0'}}>
                    {isLoading ? <LoadingSpinner/> :
                    <>
                    <ul id='ulBusqueda'>
                    { !items2 ? 'Cargando Top Conéctate Perú....' : items2.map( (item,index) => {
                        
                    
                            /*onClick={(e) => handleRedirect(item.idAnuncio,item.Latitud, item.Longitud)}*/
                            /*
                             "Id" => $row['Id'],
                "NombreGrupo" => $row['NombreGrupo'],
                "DescripcionGrupo" => $row['DescripcionGrupo'],
                "NormasGrupo" => $row['NormasGrupo'],
                "PreguntasGrupo" => $row['PreguntasGrupo'],
                "GrupoCerrado" => $row['GrupoCerrado'],
                "DescripcionGrupo" => $row['ImagenGrupo'],
                "ImagenGrupoAdm" => $row['ImagenGrupoAdm'],
                "FechaCreacion" => $row['FechaCreacion'],
                "idusuario" => $row['idusuario'],
                            */

                            return(
                                <li   key={index} className='ulbuquedacss'>
                                <BusquedaGrupos IdGrupo = {item.Id} NombreGrupo={item.NombreGrupo} DescripcionGrupo={item.DescripcionGrupo} GrupoCerrado={item.GrupoCerrado} ImagenGrupo={item.ImagenGrupo} UsuarioAdmin={item.idusuario}></BusquedaGrupos>
                                </li>
                            
                            )
                      
                    })}
            
            </ul>
            </>
            }
                    </div>
                </Col>
                <Col sm={4} className="d-none d-sm-block">
                    {(usuarioActivo == null || usuarioActivo == '') ? <></> : 
                    <>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <Container style={{padding:'0 0 0 0'}}>
                                    <Row style={{height:'20px'}}>
                                        <Col sm={2}>
                                        </Col>
                                        <Col sm={4}>
                                        <p><b style={{fontSize:'14pt'}}>Mis grupos</b></p>
                                        </Col>
                                        <Col sm={4}>
                                        
                                        </Col>
                                        <Col sm={2}>
                                        
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col sm={12}>
                                <TopGrupos></TopGrupos>
                            </Col>
                        </Row>
                    </Container>
                    </>}
                    
                </Col>
                <Col sm={1}>
                </Col>
            </Row>


        </Container>
    </Layout>



</>);


}

export default ListaGrupos;